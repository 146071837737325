// 
// badge.scss
//

.badge {
    color: $white;
}

.badge-dark {
    color: $gray-200;
}